import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

export default ({ store }) => {
  let customMessages = {};
  if (process.client) {
    customMessages = {
      required: store.state?.translations?.templates['field-error-required'],
      email: store.state?.translations?.templates['field-error-email'],
    };
  }

  for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, {
      ...validation,
      message: customMessages[rule]?.replace(':field', '{_field_}') || validation.message,
    });
  }

  Vue.component('ValidationObserver', ValidationObserver);
  Vue.component('ValidationProvider', ValidationProvider);
};
