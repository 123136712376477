const localeToLanguage = (locale) => {
  if (typeof locale !== 'string') {
    throw new TypeError(`Expected locale to be string, got: ${JSON.stringify(locale)}`);
  } else if (!/[a-z]{2}[_-][a-z]{2}/i.test(locale)) {
    throw new Error(`Unsupported locale format received: ${locale}`);
  }

  const normalised = locale.toLowerCase().replaceAll('_', '-');
  const localeParts = normalised.split('-');

  // Language is following the ietf_language_tag from the language table.
  return localeParts[0] === localeParts[1] ? localeParts[0] : normalised;
};

export default localeToLanguage;
