
import { createPopper } from '@popperjs/core';
import { mapState } from 'vuex';

export default {
  name: 'MyAccountPopup',
  data() {
    return {
      modal: false,
      items: [],
      popperInstance: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      firstName: (state) => state.auth.user?.firstName || '-',
    }),
  },
  mounted() {
    document.addEventListener('click', this.close);
    this.initPopper();
    this.setItems();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    async setItems() {
      this.items = [
        {
          icon: 'lock',
          title: this.$i18n('customer.overview'),
          path: this.$dynamicRouter.toLocaleRoute('/account/overview'),
        },
        {
          icon: 'board',
          title: this.$i18n('customer.reservation-title'),
          path: this.$dynamicRouter.toLocaleRoute('/account/reservations'),
        },
        {
          icon: 'cash',
          title: this.$i18n('merchant.receipts'),
          path: this.$dynamicRouter.toLocaleRoute('/account/invoices'),
        },
        {
          icon: 'comment-processing-outline',
          title: this.$i18n('customer.reviews-title'),
          path: this.$dynamicRouter.toLocaleRoute('/account/reviews'),
        },
        {
          icon: 'percent',
          title: this.$i18n('reservation.vouchers'),
          path: this.$dynamicRouter.toLocaleRoute('/account/referrals-slug'),
        },
      ];
    },
    initPopper() {
      this.popperInstance = createPopper(this.$el, this.$refs.modal, {
        placement: 'bottom-end',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
        ],
      });
    },
    toggleModal() {
      this.modal = !this.modal;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.modal = false;
      }
    },
    logout() {
      this.toggleModal();
      this.$auth.logout().then(() => {
        this.$router.push('/login');
      });
    },
  },
};
