
export default {
  computed: {
    gtmKey() {
      return this.$store.state.language.gtm_key;
    },
  },
  mounted() {
    this.loadGTM();
  },
  methods: {
    loadGTM() {
      const { gtmKey } = this;
      if (gtmKey) {
        /* eslint-disable */
        window.loadTagManager = function (w, d, s, l) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = `https://www.googletagmanager.com/gtm.js?id=${gtmKey}` + dl;
          f.parentNode.insertBefore(j, f);
        };
        if (typeof tagManagerDelay === 'undefined') {
          window.loadTagManager(window, document, 'script', 'dataLayer');
        }
        /* eslint-enable */
      }
    },
  },
};
