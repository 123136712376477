import AuthService from '~/services/authService';

export default async (context) => {
  const {
    app: { router },
    $auth,
    store,
  } = context;

  const authBaseUrl = process.server ? context.$config.authBaseUrl : `${window.origin}/auth`;
  const authService = AuthService('default', authBaseUrl);
  const { csrf } = await authService.refresh();

  if (csrf) {
    store.commit('auth/setCsrf', csrf);
  }

  /** @type {null|Function} */
  let abort = null;

  router.beforeEach(async (_, __, next) => {
    if (store.getters['auth/loggedIn']) {
      if (abort) {
        abort();
        abort = null;
      }

      abort = $auth.pbxService.abortableFetchUser().abort;
    } else {
      await $auth.fetchUser();
    }

    store.commit('bucket/update', {
      key: 'user',
      value: store.getters['auth/loggedIn']
        ? {
            name: store.getters['auth/fullName'],
            email: store.state.auth.user.email,
            email_hashed: store.state.auth.user.emailHashed,
          }
        : {
            name: '',
            email: '',
            email_hashed: '',
          },
    });

    next();
  });
};
