import { Plugin } from '@nuxt/types';
import { Paths } from '~/types/Paths';

declare module 'vue/types/vue' {
  interface Vue {
    $paths: Paths;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $paths: Paths;
  }

  interface Context {
    $paths: Paths;
  }
}

const pathsPlugin: Plugin = ({ req, isDev }, inject) => {
  const paths = {
    parseRoute(route: string): string {
      return `/` + route.replace(/^\/|\/$/g, '');
    },
    assetsUrl: 'https://assets.parkos.com/assets/',
  };

  inject('paths', paths);
};

export default pathsPlugin;
