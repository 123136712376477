
import CaretDown from '~/components/icons/IconCaretDown.vue';

export default {
  components: {
    CaretDown,
  },
  props: {
    caretActiveClass: {
      type: String,
      default: () => 'text-orange-500',
      required: false,
    },
    narrow: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  data() {
    return {
      toggled: false,
    };
  },
};
