
export default {
  name: 'OpeningHours',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    items() {
      return this.$store.state.openingHours;
    },
    show: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      },
    },
  },
};
