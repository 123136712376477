// eslint-disable-next-line no-empty-pattern
export default ({}, inject) => {
  inject(
    'waitUntil',
    (condition, checkInterval = 100) =>
      new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!condition()) return;
          clearInterval(interval);
          resolve();
        }, checkInterval);
      }),
  );
};
