import merge from 'lodash/merge';
import { Context, Plugin } from '@nuxt/types';

const dynamicRouter: Plugin = async (context: Context, inject): Promise<void> => {
  let routeSlugMap: { [key: string]: string } = {};

  const getRouteSlugMap = async () => {
    const lang = context.store.state.language?.lang;
    if (Object.keys(routeSlugMap).length === 0 && lang) {
      const slugs = await context.$pbx.getTranslationsValues(lang, [
        'reservations-slug',
        'reservations-view-slug',
        'reviews-slug',
        'reviews-thanks-slug',
        'invoices-slug',
        'receipts-slug',
        'overview-slug',
        'referrals-slug',
      ]);

      routeSlugMap = {
        reservations: slugs['reservations-slug'],
        reservationsView: slugs['reservations-view-slug'],
        reviews: slugs['reviews-slug'],
        reviewsThanks: slugs['reviews-thanks-slug'],
        invoices: slugs['invoices-slug'],
        receipts: slugs['receipts-slug'],
        overview: slugs['overview-slug'],
        'referrals-slug': slugs['referrals-slug'],
      };

      Object.keys(routeSlugMap).forEach((key) => {
        routeSlugMap[key] = routeSlugMap[key]
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036F]/g, '')
          .replace(/[^a-zA-Z0-9]/g, '-');
      });
    }

    return routeSlugMap;
  };

  const getTranslatedRoutes = (map: object): { [key: string]: string } => {
    const id = context.store.state.language?.id;
    if (!id) {
      return {};
    }

    // This was added as a fallback for the case when the translations are not set
    const defaults = {
      reservations: 'reservations',
      reservationsView: 'view',
      reviews: 'reviews',
      reviewsThanks: 'thanks',
      invoices: 'invoices',
      receipts: 'receipts',
      overview: 'overview',
      'referrals-slug': 'referrals-slug',
    };

    return merge(defaults, map);
  };

  const getRouteComponentMap = (translated: { [key: string]: string }): [RegExp, string][] =>
    Object.entries({
      [`${translated.reservations}/?$`]: 'PagesReservations',
      [`${translated.reservations}/(?<id>[0-9a-z]+)/${translated.reservationsView}/?$`]: 'PagesReservationsId',
      'reviews/?$': 'PagesReviews',
      'review/add/(?<id>[0-9a-z]+)/?$': 'PagesReviewsId',
      'review/edit/(?<id>[0-9a-z]+)/?$': 'PagesReviewsId',
      'review/success/(?<id>[0-9a-z]+)/?$': 'PagesReviewsIdThanks',
      [`${translated.invoices}/?$`]: 'PagesInvoices',
      [`${translated.receipts}/?$`]: 'PagesReceipts',
      [`${translated.overview}/?$`]: 'PagesOverview',
      [`${translated['referrals-slug']}/?$`]: 'PagesVouchers',
    }).map(([key, value]) => [new RegExp(key, 'i'), value]);

  const getPageTypeByComponent = (component: string): string =>
    ({
      PagesOverview: 'my_account_overview',
      PagesReservations: 'my_account_reservations',
      PagesReservationsId: 'my_account_reservation_details',
      PagesReviews: 'my_account_reviews',
      PagesInvoices: 'my_account_invoices',
      PagesReceipts: 'my_account_receipts',
      PagesVouchers: 'my_account_vouchers',
      PagesReviewsId: 'my_account_reviews_edit',
      PagesReviewsIdThanks: 'my_account_review_success',
    })[component] ?? '';

  const getInverseRouteNameByComponent = (component: string): string | null =>
    ({
      PagesReviewsId: 'reviews-add',
    })[component] ?? null;

  await getRouteSlugMap();

  const toLocaleRoute = (path: string) => {
    const slug = getTranslatedRoutes(routeSlugMap);

    const englishToLocaleRoutes: [RegExp, string][] = Object.entries({
      'reservations/?$': `/account/${slug.reservations}/`,
      'reservations/(?<id>[0-9a-z]+)/view/?$': `/account/${slug.reservations}/:id/${slug.reservationsView}/`,
      'reviews/?$': '/account/reviews',
      'review/add/(?<id>[0-9a-z]+)/?$': '/account/review/add/:id/',
      'review/edit/(?<id>[0-9a-z]+)/?$': '/account/review/edit/:id/',
      'review/success/(?<id>[0-9a-z]+)/?$': '/account/review/success/:id/',
      'invoices/?$': `/account/${slug.invoices}`,
      'receipts/?$': `/account/${slug.receipts}`,
      'overview/?$': `/account/${slug.overview}`,
      'referrals-slug/?$': `/account/${slug['referrals-slug']}`,
    }).map(([key, value]) => [new RegExp(key, 'i'), value]);

    const route = englishToLocaleRoutes.find(([key]) => key.test(path)) ?? [];
    const [expression] = route;
    let [, translatedPath] = route;

    if (!expression || !translatedPath) {
      // eslint-disable-next-line no-console
      console.warn(`Route ${path} not found, returning the original path.`);
      return path;
    }

    const groups = expression.exec(path)?.groups ?? {};
    Object.entries(groups).forEach(([key, value]) => {
      translatedPath = translatedPath?.replace(`:${key}`, value);
    });

    return translatedPath;
  };

  const engine = {
    routes: <[RegExp, string][]>[],
    toLocaleRoute,
    fetchRoutes: async () => {
      if (engine.routes.length === 0) {
        const map = await getRouteSlugMap();
        const translated = getTranslatedRoutes(map);
        engine.routes = getRouteComponentMap(translated);
      }

      return engine.routes;
    },
    match: async (path: string) => {
      const routes = await engine.fetchRoutes();

      if (routes.length === 0) {
        return null;
      }

      const [expression, component] = routes.find(([key]) => key.test(path)) ?? [];

      if (!expression || !component) {
        return null;
      }

      const params = Object.fromEntries(
        Object.entries(expression.exec(path)?.groups ?? {}).map(([key, value]) => [key, value]),
      );

      return {
        pageType: getPageTypeByComponent(component),
        inverseRouteName: getInverseRouteNameByComponent(component),
        component,
        params,
      };
    },
    matchInverse: async (name: string, params: object = {}) => {
      const route = {
        'reviews-add': '/account/reviews/:id',
        'edit-reservation': '/account/reservations/:id',
      }[name];

      if (!route) {
        return null;
      }

      const englishRoute = Object.entries(params).reduce((acc, [key, value]) => {
        acc.replace(`:${key}`, value);
        return acc;
      }, route);

      return toLocaleRoute(englishRoute);
    },
  };

  inject('dynamicRouter', engine);
  context.app.$dynamicRouter = engine;
};

// noinspection JSUnusedGlobalSymbols
export default dynamicRouter;
