export const mutations = {
  addAlert(state, { title = null, text = null, type = 'error', persistence = false }) {
    state.alerts.push({
      title,
      text,
      type,
      persistence,
    });
  },
  addInputMessage(state, { input = null, text = null, type = 'error', persistence = false }) {
    state.inputMessages.push({
      input,
      text,
      type,
      persistence,
    });
  },
  addInputMessages(state, inputMessages) {
    state.inputMessages.push(...inputMessages);
  },
  resetAlerts(state, force) {
    if (force) {
      state.alerts = [];
    } else {
      state.alerts = state.alerts.filter((alert) => alert.persistence);
    }
  },
  resetInputMessages(state, force) {
    if (force) {
      state.inputMessages = [];
    } else {
      state.inputMessages = state.inputMessages.filter((inputMessage) => inputMessage.persistence);
    }
  },
  resetAll(state, force) {
    if (force) {
      state.alerts = [];
      state.inputMessages = [];
    } else {
      state.alerts = state.alerts.filter((alert) => alert.persistence);
      state.inputMessages = state.inputMessages.filter((inputMessage) => inputMessage.persistence);
    }
  },
};

export const getters = {
  getInputError: (state) => (input) => {
    const error = state.inputMessages.find((msg) => msg.input === input && msg.type === 'error');
    if (error) {
      return error.text;
    }
    return null;
  },
};

export const state = () => ({
  alerts: [],
  inputMessages: [],
});
