export default {
  head() {
    const script = [];
    const $cheq = this.$cheq(this.language.domain)?.script;

    if ($cheq) {
      script.push({
        hid: 'cheq',
        ...$cheq,
      });
    }

    if (this.language.extra_gtm_key) {
      const attrs = {
        hid: 'ExtraGTM',
        src: `https://www.googletagmanager.com/gtag/js?id=${this.language.extra_gtm_key}`,
        async: true,
      };
      script.push(attrs);
    }

    if (this.language.domain === 'parkos.nl' && this.$config.gtagTrackingId) {
      const attrs = {
        src: `https://www.googletagmanager.com/gtag/js?id=${this.$config.gtagTrackingId}&l=tagManagerDataLayer`,
        async: true,
      };
      script.push(attrs);
    }

    return {
      script,
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
};
