
import CaretRight from '~/components/icons/IconCaretRight.vue';

export default {
  components: {
    CaretRight,
  },
  props: {
    navigation: {
      type: Array,
      default: () => [],
    },
  },
};
