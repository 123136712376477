import to from '~/utils/to';
import axios from 'axios';
import getPbxInstance from '~/services/pbxService';

async function automaticLogin({ store, app, route, res, req, $config }) {
  if (process.client) return;

  const orderId = route.query.iorderid || route.query.order_id;

  // Without a valid order id, we can't automatically log the user in.
  if (!orderId) return;

  // Without a valid cid or email, we can't automatically log the user in.
  if (!route.query.cId && !route.query.email) return;

  const param = {
    email: route.query.email,
    cId: route.query.cId,
    reservation_number: orderId,
    remember: true,
    language_id: store.state.language.id,
  };

  const hasCID = route.query.cId && route.query.cId.toString().trim().length > 0;
  let url = `${$config.authBaseUrl}`;

  url += hasCID ? '/reservation-number-cid/' : '/reservation-number/';

  const clientIp = req.connection.remoteAddress || req.socket.remoteAddress;
  const [error, response] = await to(
    axios.post(url, param, {
      headers: {
        origin: req.headers.host,
        'x-forwarded-for': req.headers['x-forwarded-for'] ? req.headers['x-forwarded-for'] : clientIp,
      },
    }),
  );

  if (error) {
    console.error('[AutomaticLogin]', error);
    return;
  }

  const cookies = [];

  response.headers['set-cookie'].forEach((cookie) => {
    const value = cookie.split('; ')[0].split('=')[1];
    if (value) cookies.push(cookie);
  });

  res.setHeader('Set-Cookie', cookies);

  if (response.data?.csrf) {
    store.commit('auth/setCsrf', response.data.csrf);
  }

  const pbxService = getPbxInstance('automaticLogin', $config.pbxBaseUrl, store, cookies.join('; '));

  const [, user] = await to(pbxService.fetchUser());

  if (user) {
    store.commit('auth/setUser', user);
  }

  const targetPath = () => {
    const ref = route.query.target_ref;

    return (
      {
        'edit-reservation': app.$helpers.getEditReservationLink({ order_id: orderId }),
        'reviews-add': app.$dynamicRouter.toLocaleRoute(`/account/review/add/${orderId}`),
      }[ref] ?? app.$dynamicRouter.toLocaleRoute('/account/overview')
    );
  };

  res.setHeader('Content-Type', 'text/html; charset=utf-8');
  res.write(`
    <!DOCTYPE html>
    <html>
      <head><meta http-equiv="refresh" content="0; url='${targetPath()}'"></head>
      <body></body>
    </html>
  `);

  res.end();
}

export default automaticLogin;
