import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=31884830"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=31884830&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReferralCashback: require('/app/components/ReferralCashback.vue').default,Logo: require('/app/components/Logo.vue').default,HeaderMyAccountPopup: require('/app/components/header/MyAccountPopup.vue').default,HeaderMobileUserPopup: require('/app/components/header/MobileUserPopup.vue').default,Header: require('/app/components/header/Header.vue').default})
