export const mutations = {
  set(state, _data) {
    state.data = _data;
  },
};

export const actions = {};

export const state = () => ({
  data: undefined,
});
