export const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setCsrf(state, csrf) {
    state.csrf = csrf;
  },
};

export const getters = {
  loggedIn(state) {
    return !!state.user;
  },
  fullName(state) {
    return `${state.user?.firstName} ${state.user?.lastName}`;
  },
};

export const state = () => ({
  user: null,
  csrf: null,
});
