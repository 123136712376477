
export default {
  props: {
    slug: undefined,
  },

  computed: {
    blockContent() {
      if (!this.template?.page_content && this.slug === 'footer_airports') {
        return `
          <ul>\r\n
          <li><strong>${this.$i18n('general.airports')}</strong></li>\r\n
          ${this.airports
            .map(
              (airport) =>
                `<li><a class="text-white hover:text-white" href="/${airport.slug}/">${airport.name}</a></li>\r\n`,
            )
            .slice(0, 5)
            .join('')}
          </ul>
          `;
      }
      if (this.slug === 'footer_airports') {
        return this.template?.page_content.replace('#airports', '/#airports') ?? '';
      }
      return this.template?.page_content ?? '';
    },
    template() {
      return this.$store.state.pageTemplate[this.slug];
    },
    airports() {
      return this.$store.state.airports;
    },
  },
};
