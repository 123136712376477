
import Logo from '~/components/Logo.vue';
import Socials from '~/components/Socials.vue';
import PageTemplate from '~/components/PageTemplate.vue';
import ToggleInfo from '~/components/Airport/ToggleInfo.vue';

export default {
  components: {
    ToggleInfo,
    Logo,
    Socials,
    PageTemplate,
  },
  data() {
    return {
      paymentIcons: [
        'payment_ideal',
        'payment_amex',
        'payment_mastercard',
        'payment_paypal',
        'payment_visa',
        'payment_star',
      ],
      parsedContent: {},
    };
  },
  computed: {
    slugs() {
      return ['footer_airports', 'footer_information', 'footer_about'];
    },
    language() {
      return this.$store.state.language;
    },
    termsConditionsContent() {
      return this.$store.state.pageContent.termsConditionsContent;
    },
    privacyPolicyContent() {
      return this.$store.state.pageContent.privacyPolicyContent;
    },
    termsConditionsPageContent() {
      if (
        this.termsConditionsContent &&
        this.language &&
        this.language.lang &&
        this.termsConditionsContent[this.language.lang]
      ) {
        const currentContent = this.termsConditionsContent[this.language.lang];
        return {
          title: currentContent.title,
          url: `/${currentContent.slug}.html`,
        };
      }

      return { title: '', url: '' };
    },
    privacyPolicyPageContent() {
      if (
        this.privacyPolicyContent &&
        this.language &&
        this.language.lang &&
        this.privacyPolicyContent[this.language.lang]
      ) {
        const currentContent = this.privacyPolicyContent?.[this.language.lang];
        return {
          title: currentContent.title,
          url: `/${currentContent.slug}.html`,
        };
      }

      return { title: '', url: '' };
    },
    showSupport() {
      return this.$route.path !== '/static';
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.templates.forEach((comp) => {
        const parsed = this.parseContent(comp.$el);
        this.parsedContent[comp.slug] = parsed;
      });
      this.parsedContent = { ...this.parsedContent };
    });
  },
  methods: {
    parseContent(ulElement) {
      try {
        if (!ulElement) {
          return undefined;
        }
        const title = ulElement.querySelector('li:first-child');
        const content = ulElement.querySelectorAll('li:not(:first-child)');
        if (!title || !content) {
          return null;
        }
        const data = [];
        content.forEach((e) => {
          const a = e.querySelector('a');
          if (a) {
            data.push({ text: e.innerText, url: a.href });
          } else {
            const img = e.querySelector('img');
            if (img) {
              data.push({
                img: img.outerHTML,
                parentStyle: (e && e.attributes && e.attributes.style && e.attributes.style.value) || '',
              });
            }
          }
        });
        return { title: title.innerText, content: data };
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }
      return {};
    },
  },
};
