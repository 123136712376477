
import IconTwitter from '~/components/icons/IconTwitter.vue';
import IconYoutube from '~/components/icons/IconYoutube.vue';
import IconFacebook from '~/components/icons/IconFacebook.vue';
import IconInstagram from '~/components/icons/IconInstagram.vue';
import IconPinterest from '~/components/icons/IconPinterest.vue';

export default {
  components: {
    IconTwitter,
    IconYoutube,
    IconFacebook,
    IconInstagram,
    IconPinterest,
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
    twitter() {
      return this.language.socials.twitter;
    },
    facebook() {
      return this.language.socials.facebook;
    },
    youtube() {
      return this.language.socials.youtube;
    },
  },
};
