
import CallUs from '~/components/global/Support/CallUs.vue';
import MailUs from '~/components/global/Support/MailUs.vue';
import OpeningHours from '~/components/global/Support/OpeningHours.vue';

export default {
  name: 'Support',
  components: {
    CallUs,
    MailUs,
    OpeningHours,
  },
  data() {
    return {
      modal: false,
    };
  },
  methods: {
    openModal() {
      this.modal = true;
    },
  },
};
