/* eslint-disable consistent-return */
import axios from 'axios';
import { stringify } from 'querystring';
import AuthService from '~/services/authService';
import getAbortController from '~/utils/getAbortController';

export class PbxService {
  constructor(baseURL, store, cookie = null) {
    this.pages = [];
    this.pageContent = [];

    this.axiosInstance = axios.create({
      baseURL,
      withCredentials: true,
    });

    if (cookie) {
      this.axiosInstance.defaults.headers.common.cookie = cookie;
    }

    this.axiosInstance.interceptors.request.use(
      (config) => {
        if (['post', 'POST'].includes(config.method)) {
          Object.assign(config.data, {
            csrf: store.state.auth.csrf || null,
          });
        }

        if (process.server) {
          config.headers['User-Agent'] =
            `MPL-MY-ACCOUNT/${process.env.VERSION} (+https://mpl-my-account.${process.env.ENVIRONMENT}.parkos.dev)`;
        }

        return config;
      },
      (error) => Promise.reject(error),
    );

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const config = error?.config;

        if (axios.isCancel(error)) {
          return;
        }

        // Using a 403 status code for CSRF token errors better aligns with the semantics of HTTP status codes.
        // A 403 status code indicates that the server understood the request but refuses to fulfill it due to
        // insufficient permissions or, in this case, a CSRF protection measure.
        if (error?.response?.status === 403 && !config?.sent) {
          config.sent = true;
          const authService = AuthService('default');
          const { status, csrf } = await authService.refreshResult();

          if (csrf) {
            store.commit('auth/setCsrf', csrf);
            return this.axiosInstance(config);
          }

          if (!status) {
            store.commit('auth/setUser', null);
          }
        } else {
          console.error(`PBX Response error: ${error?.response?.status} - url: /${error.config?.url}`);
          console.error(error);
        }

        return Promise.reject(error);
      },
    );

    this.refreshes = {
      pageContent: false,
      services: false,
    };
  }

  refresh = function () {
    this.refreshes.pageContent = true;
    this.refreshes.services = true;
  };

  static generateQuery(path, query) {
    const stringified = stringify(query, '&', '=', {
      arrayFormat: 'bracket',
      skipNull: true,
      skipEmptyString: true,
    });
    const value = path.includes('?') ? `&${stringified}` : `?${stringified}`;

    return `${path}${stringified ? value : ''}`;
  }

  async getUserReservations(languageId, search, page, perPage, type = null) {
    const url = PbxService.generateQuery('reservations', {
      languageId,
      search,
      page,
      perPage,
      type,
    });
    const { data } = await this.axiosInstance.get(url);

    return data;
  }

  async getUserInvoices(languageId, search, page, perPage, type) {
    const url = PbxService.generateQuery('invoices', {
      languageId,
      search,
      page,
      perPage,
      type,
    });
    const {
      data: { data },
    } = await this.axiosInstance.get(url);

    return { data };
  }

  async fetchUser() {
    const { data } = await this.axiosInstance.get('users/me/');
    return data;
  }

  abortableFetchUser() {
    const controller = getAbortController();
    const promise = this.axiosInstance.get('users/me/', { signal: controller.signal });

    return {
      promise,
      abort: controller.abort.bind(controller),
    };
  }

  async getUserReservation(reservationId, languageId) {
    const url = PbxService.generateQuery(`reservations/${reservationId}`, {
      languageId,
    });
    const { data } = await this.axiosInstance.get(url);

    return data;
  }

  async getReservationInfo(reservationId, languageId) {
    const url = PbxService.generateQuery(`reservations/${reservationId}/info`, {
      languageId,
    });
    const { data } = await this.axiosInstance.get(url);

    return data;
  }

  async updateReservation(reservationId, languageId, data) {
    const { data: response } = await this.axiosInstance.put(
      `reservations/${reservationId}?languageId=${languageId}`,
      data,
    );
    return response;
  }

  async getUserReviews(languageId, search, page, perPage, type) {
    const url = PbxService.generateQuery('reviews', {
      languageId,
      search,
      page,
      perPage,
      type,
    });
    const { data } = await this.axiosInstance.get(url);

    return data;
  }

  async getUserReview(reservationCode, languageId) {
    const url = PbxService.generateQuery(`reviews/${reservationCode}`, { languageId });
    const { data } = await this.axiosInstance.get(url);

    return data;
  }

  async storeOrUpdateReview(reservationCode, languageId, questions) {
    const { data } = await this.axiosInstance.post(`reviews/${reservationCode}/`, {
      languageId,
      questions,
    });

    return data;
  }

  async getUserReferral(languageId) {
    const { data } = await this.axiosInstance.get(
      PbxService.generateQuery('users/referral', {
        languageId,
      }),
    );

    return data;
  }

  /**
   * @param key {string}
   * @returns {Promise<{lang: number, value: string}[]>}
   */
  async getTranslationValues(key) {
    const { data } = await this.axiosInstance.get(PbxService.generateQuery('translation', { key }));
    return data;
  }

  async getTranslationsValues(lang, keys) {
    const { data } = await this.axiosInstance.get(PbxService.generateQuery('translations', { keys, lang }));

    keys.filter((key) => !data[key]).forEach((key) => console.error(`Missing translations for key: ${key}`));

    return data;
  }

  /**
   * @param path {string}
   * @param query {object}
   * @returns {Promise<any>}
   */
  async get(path, query) {
    const { data } = await this.axiosInstance.get(PbxService.generateQuery(path, query));
    return data;
  }

  getPageContent(devtitle) {
    const self = this;

    if (!self.pageContent[devtitle] || self.refreshes.pageContent === true) {
      self.refreshes.pageContent = false;
      const url = PbxService.generateQuery('content/pages', { devtitle });

      const fetch = new Promise((resolve, reject) => {
        self.axiosInstance
          .get(url)
          .then((response) => {
            self.pageContent[devtitle] = response.data;
            resolve(self.pageContent[devtitle]);
          })
          .catch((e) => {
            if (self.pageContent[devtitle] !== null) {
              resolve(self.pageContent[devtitle]);
            } else {
              reject(e);
            }
          });
      });

      return fetch;
    }

    return new Promise((resolve) => {
      resolve(self.pageContent[devtitle]);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async getHeaderAirportsConfiguration() {
    return {
      default: {
        columns: 4,
      },
      Canada: {
        columns: 3,
      },
      'United States': {
        columns: 3,
      },
    };
  }

  async getLanguages(limit = 50) {
    const self = this;
    try {
      const url = PbxService.generateQuery('languages', { limit });
      const { data } = await self.axiosInstance.get(url);
      return data.data;
    } catch (e) {
      return e;
    }
  }

  async getTranslations(lang) {
    const self = this;
    try {
      const url = PbxService.generateQuery('content/translations', { lang });
      const { data } = await self.axiosInstance.get(url);
      return data;
    } catch (e) {
      return e;
    }
  }

  async getAirports(lang, limit = 250, orderBy = 'locations_content.maintitle') {
    const self = this;
    try {
      const url = PbxService.generateQuery('content/airports', { lang, limit, orderBy });
      const { data } = await self.axiosInstance.get(url);

      return data.data;
    } catch (e) {
      return e;
    }
  }

  async getPageTemplate(slug, lang) {
    const self = this;
    try {
      const url = PbxService.generateQuery('content/page-templates', { lang, slug });
      const { data } = await self.axiosInstance.get(url);
      return data.data[lang];
    } catch (e) {
      return e;
    }
  }

  async getOpeningHours(lang) {
    try {
      const url = PbxService.generateQuery('customer-service/opening-times.json', { lang });
      const response = await this.axiosInstance.get(url);

      return response.data;
    } catch (error) {
      console.log('Error fetching opening hours:', error);
      throw error;
    }
  }
}

const pbxInstances = {};

/**
 * @param name
 * @param baseUrl
 * @param store
 * @param cookie
 * @returns {typeof PbxService}
 */
function getPbxInstance(name, baseUrl, store, cookie) {
  pbxInstances[name] = new PbxService(baseUrl, store, cookie);
  return pbxInstances[name];
}

export default getPbxInstance;
