
export default {
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    toggle() {
      this.$emit('toggled', !this.open);
    },
  },
};
