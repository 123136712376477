import { Plugin } from '@nuxt/types';
import getInstance from '~/services/pbxService';

declare module 'vue/types/vue' {
  interface Vue {
    $pbx: any;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $pbx: any;
  }

  interface Context {
    $pbx: any;
  }
}

const pbxPlugin: Plugin = (context, inject): void => {
  const cookie = context.req?.headers?.cookie || null;
  const baseURL = process.server ? context.$config.pbxBaseUrl : `${window.origin}/pbx-bff`;
  const pbx = getInstance('default', baseURL, context.store, cookie);
  inject('pbx', pbx);
  context.app.$pbx = pbx;
};

export default pbxPlugin;
