import { Context, Plugin } from '@nuxt/types';
import { Store } from 'vuex';
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime';
import { Helpers } from '~/types/Helpers';
import { EditReservationLinkParams } from '~/types/EditReservationLinkParams';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

class HelpersPlugin implements Helpers {
  private readonly store: Store<any>;

  private readonly $config: NuxtRuntimeConfig;

  constructor(context: Context) {
    this.store = context.store;
    this.$config = context.$config;
  }

  getEditReservationLink(object: EditReservationLinkParams) {
    const params = Object.entries(
      omitBy(
        {
          ...object,
          ref: 'nma',
        },
        isNil,
      ) as { [key: string]: string },
    );
    const searchParams = new URLSearchParams(params).toString();

    const reservationSlug = this.store.state.translations.customer['reservations-slug'].toLowerCase();

    return `/account/${reservationSlug}/${object.order_id}/?${searchParams}`;
  }

  // eslint-disable-next-line class-methods-use-this
  pageTitle(pageTitle: string): string {
    return pageTitle;
  }

  // eslint-disable-next-line class-methods-use-this
  formatCurrency(value: any, isoCode?: string, lang?: string): string {
    const currentIsoCode = isoCode || this.store.state.language?.currency?.iso_code || 'EUR';
    const currentLang = lang || this.store.state.language?.lang || 'en-eu';
    const parsedValue = parseFloat(value);
    if (!Number.isNaN(parsedValue)) {
      return new Intl.NumberFormat(currentLang, {
        style: 'currency',
        currency: currentIsoCode,
      })
        .format(value)
        .replace(/\D00(?=\D*$)/, '');
    }
    return value;
  }

  // eslint-disable-next-line class-methods-use-this
  defaultImage(): string {
    // eslint-disable-next-line global-require
    return require('~/static/general/no-photo-available.png');
  }

  errorHandler(error: any): void {
    if (typeof error === 'object') {
      const { response: { data: { message = null, errors = null } = {} } = {} } = error;
      if (message) {
        this.store.commit('message/addAlert', {
          title: message,
          text: message,
          type: 'error',
        });
      }
      if (Array.isArray(errors)) {
        this.store.commit(
          'message/addInputMessages',
          errors.map((err) => ({
            input: err.path,
            text: err.msg,
            type: 'error',
          })),
        );
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  readableError(error: string): string {
    return error?.replace(/\{field\}\s?/, '')?.replace(/:field\s?/, '');
  }
}

const helperPlugin: Plugin = (context: Context, inject): void => {
  const helpers: Helpers = new HelpersPlugin(context);
  inject('helpers', helpers);
  context.app.$helpers = helpers;
};

export default helperPlugin;
