const assignDataForDABucket = (state, commit) => {
  commit(
    'bucket/update',
    {
      key: 'domain',
      value: {
        country: state.language.country.name,
        country_code: state.language.country.code,
        language: state.language.lang,
        name: state.language.domain,
      },
    },
    { root: true },
  );
};

export default assignDataForDABucket;
