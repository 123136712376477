
import { mapState } from 'vuex';

export default {
  name: 'MobileUserPopup',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      items: [
        {
          icon: 'icons-icon-lock-thin',
          title: this.$i18n('customer.overview'),
          path: this.$dynamicRouter.toLocaleRoute('/account/overview'),
        },
        {
          icon: 'icons-icon-board-thin',
          title: this.$i18n('customer.reservation-title'),
          path: this.$dynamicRouter.toLocaleRoute('/account/reservations'),
        },
        {
          icon: 'icons-icon-cash',
          title: this.$i18n('merchant.receipts'),
          path: this.$dynamicRouter.toLocaleRoute('/account/invoices'),
        },
        {
          icon: 'icons-icon-comment-processing-outline',
          title: this.$i18n('customer.reviews-title'),
          path: this.$dynamicRouter.toLocaleRoute('/account/reviews'),
        },
        {
          icon: 'icons-icon-percent',
          title: this.$i18n('reservation.vouchers'),
          path: this.$dynamicRouter.toLocaleRoute('/account/referrals-slug'),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      firstName: (state) => state.auth.user?.firstName || '',
      lastName: (state) => state.auth.user?.lastName || '',
    }),
    fullName() {
      if (this.firstName || this.lastName) {
        return `${this.firstName} ${this.lastName}`;
      }
      return '-';
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    logout() {
      this.$auth.logout().then(() => {
        this.$router.push('/login');
      });
    },
  },
};
