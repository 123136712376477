import get from 'lodash/get';
import assign from 'lodash/assign';
import to from '~/utils/to';

export type State<T> = {
  response: {
    data: T[];
    total: number | null;
    next: string | null;
  };
  loading: boolean;
  loaded: boolean;
  search: string | null;
};

export type ResolveParameters<T extends object = object> = (params: { state: State<T>; rootState: object }) => object;

export type Object<T = any> = { [key: string]: T };

const createPaginatableResource = <T extends object = object>(
  prefix: string,
  resolveParameters: ResolveParameters<T>,
) => {
  // The default response is an empty array of items, with a null total and next
  // It's written in this way to avoid reference changes
  const defaultResponse = () => ({
    data: [],
    total: null,
    next: null,
  });

  const mutations = {
    appendItems(state: State<T>, items: T[]) {
      state.response.data.push(...items);
    },
    setNext(state: State<T>, next: string | null) {
      state.response.next = next;
    },
    setTotal(state: State<T>, total: number | null) {
      state.response.total = total;
    },
    setSearch(state: State<T>, search: string | null) {
      state.search = search;
    },
    setLoaded(state: State<T>, loaded: boolean) {
      state.loaded = loaded;
    },
    setLoading(state: State<T>, loading: boolean) {
      state.loading = loading;
    },
    setResponse(state: State<T>, response: State<T>['response']) {
      state.response = response;
    },
  };

  const actions = {
    async search({ commit, dispatch }: Object, term: string | null) {
      await dispatch('reset');
      commit('setSearch', term);
      dispatch('next');
    },
    reset({ commit }: Object) {
      commit('setSearch', null);
      commit('setLoaded', false);
      commit('setLoading', false);
      commit('setResponse', defaultResponse());
    },
    async next({ state, rootState, commit }: Object) {
      commit('setLoading', true);
      const next = get(state, 'response.next');

      // @ts-ignore
      const service: import('~/services/pbxService').PbxService = this.app.$pbx;
      let response;

      if (next) {
        [, response] = await to(service.get(next, {}));
      } else {
        const params = assign({}, resolveParameters({ state, rootState }), { search: state.search });
        [, response] = await to(service.get(prefix, params));
      }

      commit('appendItems', get(response, 'data', []));
      commit('setTotal', get(response, 'pagination.total.count', null));
      commit('setNext', get(response, 'links.next', null));

      commit('setLoading', false);
      commit('setLoaded', true);
    },
  };

  return {
    state: () => ({
      response: defaultResponse(),
      loading: false,
      loaded: false,
      search: null,
    }),
    mutations,
    actions,
  };
};

export default createPaginatableResource;
