
import { mapState } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';
import MegaMenu from '~/components/header/MegaMenu.vue';
import ToggleMenu from '~/components/header/ToggleMenu.vue';
import IconCaretRight from '~/components/icons/IconCaretRight.vue';
import IconUser from '~/components/icons/IconUser.vue';
import ReferralCashback from '~/components/ReferralCashback.vue';
import Logo from '../Logo.vue';
import PartnerLogo from './PartnerLogo.vue';

const { disableBodyScroll, clearAllBodyScrollLocks } = require('body-scroll-lock');

export default {
  components: {
    LazyHydrate,
    PartnerLogo,
    Logo,
    MegaMenu,
    ToggleMenu,
    IconCaretRight,
    IconUser,
    ReferralCashback,
  },
  props: {
    headerType: {
      type: String,
      required: false,
      default: 'noHeader',
    },
    parkingData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    avgPricePerDay: {
      type: String,
      required: false,
      default: '',
    },
    image: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      collapse: true,
      scrollTop: 0,
      megaMenuOpen: false,
      searchExpression: '',
      languagesExpanded: false,
      showAirportsDropdown: true,
      mobileUserPopup: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    airports() {
      return this.$store.state.airports;
    },
    airport() {
      return this.$store.state.airport;
    },
    language() {
      return this.$store.state.language;
    },
    pricePerDay() {
      if (this.airport.from_price) {
        return new Intl.NumberFormat(this.language.lang, {
          style: 'currency',
          currency: this.language.currency.iso_code ?? 'EUR',
        }).format(this.airport.from_price / 8);
      }
      return null;
    },
    zenDeskLangCode() {
      if (this.language && this.language.lang) {
        const langCode = this.language.lang;
        if (langCode === 'en-eu') {
          return 'en-150';
        }
        if (langCode === 'da-dk') {
          return 'da';
        }
        if (langCode === 'sv-se') {
          return 'sv';
        }
        return langCode;
      }
      return null;
    },
    aboutPageLink() {
      if (this.aboutPageContent) {
        const { slug } = this.aboutPageContent;
        return `/${slug}.html`;
      }

      return null;
    },
    aboutPageTitle() {
      if (this.aboutPageContent) {
        const { title } = this.aboutPageContent;
        return title;
      }

      return null;
    },
    aboutPageContent() {
      const about = this.$store.state.pageContent.aboutPageContent;

      if (about && this.language && this.language.lang) {
        return about[this.language.lang];
      }

      return null;
    },
    metaPages() {
      return this.$store.state.metaPages;
    },
    faqMeta() {
      return this.metaPages.faqMeta;
    },
    faqPageLink() {
      return this.$paths.parseRoute(this.faqMeta.slug);
    },
    faqPageTitle() {
      return this.faqMeta.title;
    },
    isSearchActive() {
      return !!(this.searchExpression && this.searchExpression !== '');
    },
  },
  mounted() {
    document.addEventListener('scroll', this.scrollCheck);
  },
  methods: {
    scrollCheck() {
      this.scrollTop = window.scrollY;
    },
    scrollToTarget(elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }
    },
    toggleMenu(open) {
      this.collapse = !open;
      const { scroller } = this.$refs;
      if (this.collapse) {
        clearAllBodyScrollLocks();
        this.resetData();
      } else {
        disableBodyScroll(scroller);
      }
    },
    megaMenuToggled(show) {
      this.megaMenuOpen = show;
      if (show && this.$screens({ md: true }, false)) {
        this.$refs.highlightElement.skipScrollLock = this.$screens({ md: true }, false);
        this.$store.commit('highlight/show', this.$refs.highlightElement);
      } else if (this.$screens({ md: true }, false)) {
        this.$store.dispatch('highlight/hide');
      }
    },
    clear() {
      this.toggleMenu();
    },
    languagesToggled(expanded) {
      this.languagesExpanded = expanded;
      disableBodyScroll(this.$refs.scroller);
    },
    resetData() {
      this.searchExpression = '';
      this.megaMenuOpen = false;
      this.languagesExpanded = false;
      this.$store.dispatch('highlight/hide');
    },
    changeAirport() {
      this.showAirportsDropdown = true;
      this.$nextTick(() => {
        this.$refs.searchForm.handleAirportToggle();
      });
    },
    toggleUserPopup() {
      this.mobileUserPopup = !this.mobileUserPopup;
    },
  },
};
