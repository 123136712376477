
export default {
  name: 'CallUs',
  computed: {
    phoneNumber() {
      return this.$i18n('general.faq-call-us-phone-number');
    },
  },
  methods: {
    openModal() {
      this.$emit('openModal');
    },
  },
};
