export const mutations = {
  setLink(state, link) {
    state.link = link;
  },
  setCreditAmount(state, creditAmount) {
    state.creditAmount = creditAmount;
  },
  setDiscountPercentage(state, discountPercentage) {
    state.discountPercentage = discountPercentage;
  },
  setLoaded(state, loaded) {
    state.loaded = loaded;
  },
  resetAllData(state) {
    state.link = null;
    state.creditAmount = null;
    state.discountPercentage = null;
  },
};

export const actions = {
  load({ commit, rootState }) {
    const languageId = rootState.language.id;
    this.app.$pbx
      .getUserReferral(languageId)
      .then(({ link = null, creditAmount = null, discountPercentage = null }) => {
        commit('setLink', link);
        commit('setCreditAmount', creditAmount);
        commit('setDiscountPercentage', discountPercentage);
        commit('setLoaded', true);
      });
  },
};

export const state = () => ({
  link: null,
  creditAmount: null,
  discountPercentage: null,
  loaded: false,
});
