import assign from 'lodash/assign';
import get from 'lodash/get';
import set from 'lodash/set';

/**
 * @type {{set: (key: string, value: any) => void}}
 */
const bucket = {
  set: (key, value) => {
    if (process.server) {
      return;
    }

    set(window.daBucket.bucket, key, value);
  },
};

const setUser = (state, user) => {
  const defaultObject = {
    customer_id_cid: '',
    name: '',
    email: '',
    email_hashed: '',
  };

  state.user = assign(defaultObject, user);
  state.login_status = get(state.user, 'email', '') === '' ? 'false' : 'true';

  bucket.set('user', state.user);
  bucket.set('login_status', state.login_status);
};

export const mutations = {
  update(state, keyValue) {
    // eslint-disable-next-line no-param-reassign
    state[keyValue.key] = keyValue.value;
    bucket.set(keyValue.key, keyValue.value);

    if (keyValue.key === 'arrival_date' || keyValue.key === 'departure_date') {
      const dateStart = new Date(state.arrival_date);
      const dateEnd = new Date(state.departure_date);
      state.number_of_days = (dateEnd.getTime() - dateStart.getTime()) / 1000 / 60 / 60 / 24 + 1;
      bucket.set('number_of_days', state.number_of_days);
    }

    if (keyValue.key === 'user') {
      setUser(state, keyValue.value);
    }
  },
};

export const state = () => ({
  domain: {
    country: '',
    country_code: '',
    language: '',
    name: '',
  },
  page_type: '',
  page_view_virtual: '',
  login_status: 'false',
  user: {
    customer_id_cid: '',
    name: '',
    email: '',
    email_hashed: '',
  },
  airport: {
    name: '',
    id: '',
    slug: '',
    dev_title: '',
  },
  merchant: {
    name: '',
    id: '',
    slug: '',
  },
  arrival_date: '',
  arrival_time: '',
  departure_date: '',
  departure_time: '',
  number_of_days: '',
  lister: {
    merchant_amount_available: '',
    merchant_amount_unavailable: '',
    lowest_price: '',
    highest_price: '',
  },
  review_score: '',
  review_amount: '',
  token: '',
  filter_state: [],
  reservation: {
    id: [],
    code: [],
    order_id: '',
  },
  cs: {
    feature: '',
    search_query: '',
    search_results_amount: '',
    section_id: '',
    section_name: '',
    article_id: '',
    article_name: '',
    created_at: '',
    updated_at: '',
  },
});
