import { Plugin } from '@nuxt/types';

const map = {
  'parkos.com': {
    id: '69f0ab0b54f36d351801f48f4cf8299e',
    class: 40762,
  },
  'parkos.co.uk': {
    id: '62063fd13f5fcdc2ba6d2b10a26806f5',
    class: 43584,
  },
  'parkos.com.au': {
    id: 'f2e1dcf4e9445ab202b0f8d089ed5653',
    class: 43585,
  },
  'parkos.nl': {
    id: '57c0eb66626cfd18463a0fd4ee55fb14',
    class: 43586,
  },
  'parkos.be': {
    id: '91085bd8f0cdab7aba6fd5bf1d93d02a',
    class: 43588,
  },
  'parkos.fr': {
    id: '4577a6aa41add37915c578da2e3261d9',
    class: 43589,
  },
  'parkos.es': {
    id: '09f1e9ce9a758d48c85ffb48e9a088c7',
    class: 43590,
  },
  'parkos.it': {
    id: 'b4a362dc136a926c7f8e42a068fbfbe4',
    class: 43591,
  },
  'parkos.se': {
    id: 'a7c108052bdcbf99b0b4e14ea7f6b582',
    class: 43592,
  },
  'parkos.pl': {
    id: '71e4526c187bfcc602e40339f40378f2',
    class: 43593,
  },
  'fr.parkos.be': {
    id: '48de3d2ddef4a2d9899f705b96bedf2b',
    class: 43594,
  },
  'eu.parkos.com': {
    id: '8cca6e5edee28e1685fe96c10e0bf3c9',
    class: 43595,
  },
  'parkos.de': {
    id: '424ef4209520f812bf5ea1387aa25f12',
    class: 43596,
  },
  'parkos.pt': {
    id: '1b6a61605c3201ec6ef6e1fb25bb6e19',
    class: 43597,
  },
  'parkos.at': {
    id: 'aa98be175be9a6124da013e5c02fe2f7',
    class: 43598,
  },
  'parkos.dk': {
    id: 'fef0e8a2d4636f32b76579d220ebdcae',
    class: 43599,
  },
};

// eslint-disable-next-line no-unused-vars
type CheqFunction = (domain: keyof typeof map) => {
  script: object;
  noScript: string;
} | null;

// @ts-ignore
declare module 'vue/types/vue' {
  interface Vue {
    $cheq: CheqFunction;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $cheq: CheqFunction;
  }

  interface Context {
    $cheq: CheqFunction;
  }
}

const cheqPlugin: Plugin = (_, inject) => {
  const cheq: CheqFunction = (domain) =>
    map[domain]
      ? {
          script: {
            async: true,
            class: `ct_clicktrue_${map[domain].class}`,
            'data-ch': domain,
            'data-jsonp': 'onCheqResponse',
            src: `https://euob.thisgreencolumn.com/sxp/i/${map[domain].id}.js`,
          },
          noScript: `<iframe src='https://obseu.thisgreencolumn.com/ns/${map[domain].id}.html?ch=${domain}' width='0' height='0' style='display:none'></iframe>`,
        }
      : null;

  inject('cheq', cheq);
};

export default cheqPlugin;
