import { render, staticRenderFns } from "./CallUs.vue?vue&type=template&id=f4f9e358&scoped=true"
import script from "./CallUs.vue?vue&type=script&lang=js"
export * from "./CallUs.vue?vue&type=script&lang=js"
import style0 from "./CallUs.vue?vue&type=style&index=0&id=f4f9e358&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4f9e358",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsIconPhone: require('/app/components/icons/IconPhone.vue').default})
