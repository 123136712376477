
import to from '~/utils/to';

export default {
  name: 'StickyNavigationBar',
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      rawItems: [],
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          path: this.$dynamicRouter.toLocaleRoute('/account/reviews/EXAMPLE'),
          name: 'account-reviews-id',
          item: 'id',
          title: this.$i18n('customer.write-review'),
        },
        {
          path: this.$dynamicRouter.toLocaleRoute('/account/review/edit/EXAMPLE'),
          name: 'account-reviews-id-edit',
          item: 'id',
          title: this.$i18n('customer.write-review'),
        },
        {
          path: this.$dynamicRouter.toLocaleRoute('/account/review/add/EXAMPLE'),
          name: 'account-reviews-id-add',
          item: 'id',
          title: this.$i18n('customer.write-review'),
        },
        {
          path: this.$dynamicRouter.toLocaleRoute('/account/reviews/EXAMPLE/thanks'),
          name: 'account-reviews-id-thanks',
          item: 'thanks',
          title: this.$i18n('customer.write-review'),
        },
        ...this.rawItems,
      ].map((item) => {
        const patternString = `^${item.path.replace('EXAMPLE', '[0-9a-z]+')}`;
        const pattern = new RegExp(patternString, 'i');

        return {
          ...item,
          href: item.path,
          activePath: pattern.test(this.$route.path),
        };
      });
    },
    items() {
      const items = this.rawItems;
      return items.map((item) => ({
        ...item,
        activePath: this.$route.path.includes(item.path),
      }));
    },
  },
  watch: {
    $route: {
      handler() {
        this.setNavigation();
      },
    },
  },
  mounted() {
    this.setItems();
    this.setNavigation();
  },
  methods: {
    async setItems() {
      this.rawItems = [
        {
          icon: 'lock',
          title: this.$i18n('customer.overview'),
          path: this.$dynamicRouter.toLocaleRoute('/account/overview'),
          name: 'account-overview',
        },
        {
          icon: 'board',
          title: this.$i18n('customer.reservation-title'),
          path: this.$dynamicRouter.toLocaleRoute('/account/reservations'),
          name: 'account-reservations',
        },
        {
          icon: 'cash',
          title: this.$i18n('merchant.receipts'),
          path: this.$dynamicRouter.toLocaleRoute('/account/receipts'),
          name: 'account-receipts',
        },
        {
          icon: 'comment-processing-outline',
          title: this.$i18n('customer.reviews-title'),
          path: this.$dynamicRouter.toLocaleRoute('/account/reviews'),
          name: 'account-reviews',
        },
        {
          icon: 'percent',
          title: this.$i18n('reservation.vouchers'),
          path: this.$dynamicRouter.toLocaleRoute('/account/referrals-slug'),
          name: 'account-referrals-slug',
        },
      ];
    },
    setNavigation() {
      const items = [
        {
          title: this.$i18n('customer.home'),
          href: '/',
        },
      ];

      const active = this.breadcrumbs.find(({ activePath }) => activePath);

      if (active) {
        if (
          [
            'account-reviews-id',
            'account-reviews-id-thanks',
            'account-reviews-id-edit',
            'account-reviews-id-add',
          ].includes(active.name)
        ) {
          items.push(this.breadcrumbs.find(({ name }) => name === 'account-reviews'));
        }

        items.push(active);
      }

      this.$emit('input', items);
    },
    async logout() {
      await to(this.$auth.logout());
      this.$router.push('/login');
    },
  },
};
