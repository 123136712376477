import Vue from 'vue';

export default ({ app }, inject) => {
  const deviceType = () => {
    if (process.server) {
      return 'desktop';
    }
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return 'mobile';
    }
    return 'desktop';
  };
  Vue.prototype.$deviceType = deviceType;
  app.deviceType = deviceType;
  inject('deviceType', deviceType);
};
