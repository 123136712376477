import { Store } from 'vuex';
import { Context, Plugin } from '@nuxt/types';
import { NuxtAppOptions } from '@nuxt/types/app';

import AuthService from '~/services/authService';
import getPbxInstance from '~/services/pbxService';
import to from '~/utils/to';

interface Credentials {
  email: string;
  reservation_number: string;
  remember?: boolean;
}

class Auth {
  readonly app: NuxtAppOptions;

  readonly store: Store<any>;

  readonly route: any;

  readonly authService: any;

  readonly pbxService: any;

  constructor(context: Context) {
    this.app = context.app;
    this.store = context.store;
    this.route = context.route;

    const authBaseUrl = process.server ? context.$config.authBaseUrl : `${window.origin}/auth`;
    this.authService = AuthService('default', authBaseUrl);

    const cookie = context.req?.headers?.cookie || null;
    const pbxBaseURL = process.server ? context.$config.pbxBaseUrl : `${window.origin}/pbx-bff`;

    this.pbxService = getPbxInstance('default', pbxBaseURL, context.store, cookie);
  }

  setCSRF(response: { [key: string]: never }) {
    if (response?.csrf) {
      this.store.commit('auth/setCsrf', response.csrf);
    }
  }

  async login(credentials: Credentials): Promise<any> {
    const response = await this.authService.login(credentials);
    this.setCSRF(response);

    return response;
  }

  async loginWithCid(credentials: Credentials): Promise<any> {
    const response = await this.authService.loginWithCid(credentials);
    this.setCSRF(response);

    return response;
  }

  async check(): Promise<boolean> {
    const [err, user] = await to(this.fetchUser());
    return err ? false : !!user;
  }

  async fetchUser(): Promise<object | null> {
    const [err, response] = await to(this.pbxService.fetchUser());
    if (err) {
      this.store.commit('auth/setUser', null);
      return null;
    }

    this.store.commit('auth/setUser', response);
    return response;
  }

  async logout(): Promise<boolean> {
    await this.authService.logout();
    this.cleanup();

    return true;
  }

  cleanup() {
    const mutations = [
      ['auth/setUser', null],
      ['auth/setCsrf', null],
      'accountOverview/resetAllData',
      'userReferral/resetAllData',
    ];

    mutations.forEach((mutation) => {
      if (typeof mutation === 'string') {
        this.store.commit(mutation);
        return;
      }

      this.store.commit(mutation[0] as string, mutation[1]);
    });

    const actions = [
      'reservations/reset',
      'reviews/invite/reset',
      'reviews/review/reset',
      'invoices/active/reset',
      'invoices/history/reset',
    ];

    actions.forEach((action) => this.store.dispatch(action));
  }
}

const authPlugin: Plugin = (context: Context, inject): void => {
  const auth: Auth = new Auth(context);

  inject('auth', auth);
  context.app.$auth = auth;
};

export default authPlugin;
