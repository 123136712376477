import get from 'lodash/get';
import getDayOfWeek from '~/utils/getDayOfWeek';
import assignDataForDABucket from '~/utils/assignDataForDABucket';
import localeToLanguage from '~/helpers/localeToLanguage';

export const strict = false;

export const state = () => ({
  languages: [],
  language: {},
  airports: [],
  translations: {},
  pageContent: {},
  headerAirportsConfiguration: {},
  openingHours: [],
});

export const actions = {
  // eslint-disable-next-line no-shadow
  async nuxtServerInit({ state, commit }, { app, req, $dynamicRouter }) {
    console.time('nuxtServerInit test');

    console.time('getLanguages test');

    const LANG_IETF = localeToLanguage(req.headers['x-locale'] || process.env.LOCALE_DEFAULT);

    state.languages = await app.$pbx.getLanguages();
    state.language = state.languages.find(({ lang }) => lang === LANG_IETF);
    console.timeEnd('getLanguages test');

    await $dynamicRouter.fetchRoutes();

    const { lang = 'en-us' } = state.language;

    console.time('Promise.all test');

    const [
      airports,
      translations,
      termsConditionsContent,
      privacyPolicyContent,
      aboutPageContent,
      headerAirportsConfiguration,
      faq,
      openingHoursData,
      footerAirports,
      footerInformation,
      footerAbout,
      footerIcons,
    ] = await Promise.all([
      app.$pbx.getAirports(lang),
      app.$pbx.getTranslations(lang),
      app.$pbx.getPageContent('terms-conditions'),
      app.$pbx.getPageContent('privacy-policy'),
      app.$pbx.getPageContent('about-us'),
      app.$pbx.getHeaderAirportsConfiguration(),
      app.$pbx.getPageContent('faq'),
      app.$pbx.getOpeningHours(lang),
      app.$pbx.getPageTemplate('footer_airports', lang),
      app.$pbx.getPageTemplate('footer_information', lang),
      app.$pbx.getPageTemplate('footer_about', lang),
      app.$pbx.getPageTemplate('footer_icons', lang),
    ]);

    console.timeEnd('Promise.all test');

    state.airports = airports.filter(
      (airport) => !!state.language?.status && (!!state.language.is_minimal_product || airport.parking_count > 0),
    );

    if (!state.language.socials.facebook) {
      delete state.language.socials.facebook;
    }

    if (!state.language.socials.twitter) {
      delete state.language.socials.twitter;
    }

    // If no YouTube link is set in the socials fallback to the default
    if (!state.language.socials.youtube) {
      state.language.socials.youtube = 'https://www.youtube.com/parkosnl';
    }

    state.translations = translations;
    state.pageContent = {
      termsConditionsContent: { [lang]: termsConditionsContent[lang] },
      privacyPolicyContent: { [lang]: privacyPolicyContent[lang] },
      aboutPageContent: { [lang]: aboutPageContent[lang] },
    };

    state.headerAirportsConfiguration = headerAirportsConfiguration;

    state.pageTemplate = {
      footer_airports: footerAirports,
      footer_information: footerInformation,
      footer_about: footerAbout,
      footer_icons: footerIcons,
    };

    state.metaPages = {
      faqMeta: faq?.[lang],
    };

    const openingHours = get(openingHoursData, [lang, 'openingHours'], []);

    state.openingHours = openingHours.map((day) => ({
      title: getDayOfWeek(day.dayOfWeek),
      value: `${day.startTime} - ${day.endTime}`,
    }));

    assignDataForDABucket(state, commit);

    console.timeEnd('nuxtServerInit test');
  },
};
