const getAbortController = (): AbortController & { signal: null | AbortSignal } => {
  if (typeof AbortController === 'undefined') {
    return {
      signal: null,
      abort: () => {
        throw new Error('AbortController is not supported in your environment');
      },
    } as any;
  }

  return new AbortController();
};

export default getAbortController;
