
import Magnifier from '~/components/icons/IconMagnifier.vue';

export default {
  components: {
    Magnifier,
  },
  props: {
    expression: {
      type: String,
      required: false,
      default: () => '',
    },
    placeholder: {
      type: String,
      required: false,
      default: () => undefined,
    },
    borderYOnly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    isClear() {
      return !this.expression || this.expression === '';
    },
  },
  methods: {
    handleSearch(event) {
      this.$emit('expressionChanged', event.target.value);
    },
    clear() {
      this.$emit('expressionChanged', '');
    },
  },
};
