import { Inject } from '@nuxt/types/app';
import { Context, Plugin } from '@nuxt/types';
import get from 'lodash/get';

const formatDatePlugin: Plugin = (context: Context, inject: Inject): void => {
  const defaultLanguage = 'en';
  const language = get(context, 'store.state.language.lang', defaultLanguage).substring(0, 2);

  const formatDate = (date: string | Date, options?: Intl.DateTimeFormatOptions): string => {
    let formatter: Intl.DateTimeFormat;

    try {
      formatter = new Intl.DateTimeFormat(language, options);
    } catch (error) {
      console.error(error);

      // In case the requested language is not supported.
      formatter = new Intl.DateTimeFormat(defaultLanguage, options);
    }

    const dateInstance = typeof date === 'string' ? new Date(date) : date;
    return formatter.format(dateInstance);
  };

  inject('formatDate', formatDate);
  context.app.$formatDate = formatDate;
};

export default formatDatePlugin;
