
export default {
  data() {
    return {
      close: false,
    };
  },
  computed: {
    cookieData() {
      return this.$store.state.referralCashback.data;
    },
    discount() {
      return this.cookieData?.discount_percentage;
    },
    name() {
      return this.cookieData?.name;
    },
    nameCapitalized() {
      if (!this.name) {
        return undefined;
      }

      return this.name
        .trim()
        .split(' ')
        .map((n) => {
          const arr = n.split('');
          arr.shift();
          return `${n[0].toLocaleUpperCase()}${arr.join('')}`;
        })
        .join(' ');
    },
  },
};
