
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      required: false,
      default: '16',
    },
    fill: {
      type: String,
      required: false,
      default: '#9797A6',
    },
  },
  computed: {
    iconComponent() {
      return `icons-icon-${this.name}`;
    },
    style() {
      return {
        width: `${this.removeSizeUnit(this.size)}px`,
        fill: this.fill,
      };
    },
  },
  methods: {
    removeSizeUnit(size) {
      if (typeof size === 'string') {
        return size.replace('px', '');
      }
      return size;
    },
  },
};
