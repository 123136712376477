
import Header from '~/components/header/Header.vue';
import Footer from '~/components/footer/Footer.vue';
import Support from '~/components/global/Support/Support.vue';
import StickyNavigationBar from '~/components/global/Layout/Default/StickyNavigationBar.vue';
import Highlight from '~/components/Highlight.vue';
import LazyHydrate from 'vue-lazy-hydration';
import Layout from '~/mixins/layout';

export default {
  components: {
    LazyHydrate,
    Highlight,
    Header,
    Footer,
    Support,
    StickyNavigationBar,
  },
  mixins: [Layout],
  data() {
    return {
      navigation: [
        {
          title: 'home',
          href: '/',
        },
      ],
    };
  },
  head() {
    const script = [];

    if (this.language.lang === 'it' || this.language.lang === 'de' || this.language.lang === 'es') {
      script.push({
        id: 'ze-snippet',
        hid: 'ze-snippet',
        src: 'https://static.zdassets.com/ekr/snippet.js?key=2a147e2d-9a66-4262-92ee-9e02607930a7',
        body: true,
      });
    }

    return {
      htmlAttrs: {
        lang: this.language.lang.slice(0, 2),
      },
      script,
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
};
