export const mutations = {
  show(state, element) {
    let el = element;
    if (element && element.$el) {
      el = element.$el;
    }
    state.elements.unshift(el);
  },
  hide(state) {
    state.elements.splice(2, 1);
  },
};

export const actions = {
  hide({ commit }) {
    commit('hide');
  },
};

export const state = () => ({
  elements: [],
});
