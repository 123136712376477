
import ClickOutside from 'vue-click-outside';
import IconCaretRight from '~/components/icons/IconCaretRight.vue';
import SearchInput from '~/components/header/SearchInput.vue';

export default {
  directives: {
    clickOutside: ClickOutside,
  },
  components: {
    IconCaretRight,
    SearchInput,
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    expression: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data() {
    return {
      show: false,
      searchExpression: '',
      halfScrolled: false,
      filteringCountry: null,
      externalShow: false,
      unfilteredCountries: [],
    };
  },
  computed: {
    selectedAirportByPicker() {
      return this.$store.state.airportPicker?.airport || {};
    },
    isUSDomain() {
      return this.$store.state.language.lang === 'en-us';
    },
    language() {
      return this.$store.state.language;
    },
    columns() {
      if (
        !this.configuration ||
        !this.configuration[this.language.country.name] ||
        !this.configuration[this.language.country.name].columns
      ) {
        return 4;
      }
      return this.configuration[this.language.country.name].columns;
    },
    configuration() {
      return this.$store.state.headerAirportsConfiguration;
    },
    airports() {
      return this.$store.state.airports;
    },
    countryAirportsFiltered() {
      const { airports } = this;
      const map = {};
      airports.forEach((airport) => {
        if (!map[airport.country.name]) {
          map[airport.country.name] = [];
        }
        if (
          !this.searchExpression ||
          this.searchExpression === '' ||
          airport.name.toLowerCase().includes(this.searchExpression.trim().toLowerCase())
        ) {
          map[airport.country.name].push(airport);
        }
      });
      // eslint-disable-next-line no-restricted-syntax
      for (const key in map) {
        if (!map[key] || !map[key].length) {
          delete map[key];
        } else {
          map[key].sort((a, b) => a.name.localeCompare(b.name));
        }
      }
      const filterCountry = this.filteringCountry;
      if (filterCountry) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in map) {
          if (filterCountry !== key) {
            map[key] = [];
          }
        }
      }
      return map;
    },
    countries() {
      const countries = Object.keys(this.countryAirportsFiltered);
      countries.forEach((country, index) => {
        countries[index] = {
          country,
          count: this.airports.filter((f) => f.country.name === country).length,
        };
      });
      return countries
        .sort((a, b) => {
          const country = this.language.native_name.replace(/(\s*)?\((.+)?\)(\s*)?/, '');
          if (country === a.country) {
            return Number.MIN_SAFE_INTEGER;
          }
          if (country === b.country) {
            return Number.MAX_SAFE_INTEGER;
          }
          return b.count - a.count;
        })
        .map((c) => c.country);
    },
  },
  watch: {
    open(value) {
      this.externalShow = false;
      this.show = value;
    },
    expression(value) {
      this.searchExpression = value;
      if (this.searchExpression && this.searchExpression !== '') {
        this.externalShow = true;
      } else {
        this.externalShow = false;
      }
    },
    show(value) {
      if (value) {
        this.$emit('toggled', true);
      } else {
        this.$emit('toggled', false);
      }
    },
  },
  created() {
    this.unfilteredCountries = Object.keys(this.countryAirportsFiltered);
  },
  methods: {
    collapseHover() {
      if (this.$screens({ md: false }, true)) {
        return;
      }
      this.show = false;
    },
    expandHover() {
      if (this.$screens({ md: false }, true)) {
        return;
      }
      this.show = true;
    },
    hide(event) {
      if (!this.show || this.$refs.airportsLabel.contains(event.target)) {
        return;
      }
      if (window && window.innerWidth < 770) {
        return;
      }
      this.show = false;
      this.clear();
      this.$emit('toggled', this.show);
    },
    toggle() {
      if (this.$screens({ md: true }, false)) {
        return;
      }
      this.show = !this.show;
      if (!this.show) {
        this.clear();
      }
      this.$emit('toggled', this.show);
    },
    filterCountry(country) {
      if (this.filteringCountry === country) {
        this.filteringCountry = null;
        return;
      }
      this.filteringCountry = country;
    },
    getColumns(countryName) {
      if (!this.configuration[countryName]) {
        if (!this.configuration.default) {
          return 4;
        }
        return this.configuration.default.columns;
      }
      return this.configuration[countryName].columns;
    },
    clear(keepShowing) {
      if (!keepShowing) {
        this.show = false;
      }
      this.filteringCountry = null;
      this.searchExpression = '';
    },
  },
};
